@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header:after {
  content: "beta";
  position: absolute;
  z-index: 1070;
  width: 150px;
  height: 30px;
  background: #fff;
  top: 30px;
  right: -10px;
  text-align: center;
  font-size: 28px;
  letter-spacing: 2px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #555199;
  line-height: 30px;
  transform: rotate(45deg);
}
